<script lang="ts" setup>
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";
import SummariesList from "./SummariesList.vue";

const props = defineProps({
  summary: {
    type: Object as PropType<Summary | null>,
    required: false,
    default: null,
  },
});
const sanctumClient = useSanctumClient();
const { locale } = useI18n();
const localePath = useLocalePath();
const { isAuthenticated } = useSanctumAuth();
const user = useSanctumUser<User>();
const { t } = useI18n();
const emit = defineEmits(["summaryCreated"]);
const { numberWords } = useText();
const { limits } = useLimits();
const { startEcho, disconnectEcho } = useEcho();
const summaryResponse = ref<Summary | null>(null);

const {
  userCanProcessSummaries,
  incrementSummariesProcessed,
  processing,
  MAX_LENGTH,
} = useSummary();

const state = ref({
  input_text: "",
  language: locale.value,
});

watch(
  () => props.summary,
  (value) => {
    if (value) {
      if (
        !state.value.input_text ||
        state.value.input_text !== value.input_text
      ) {
        state.value.input_text = value.input_text;
        state.value.language = value.language.code;
      }
    }
  },
);

const form = ref();
const deleteUrl = ref(false);
const loadingText = ref(false);
const onTextExtracted = (text: string) => {
  state.value.input_text = text;
  form.value.clear();
  loadingText.value = false;
};

const handleTrash = () => {
  state.value.input_text = "";
  deleteUrl.value = true;
  setTimeout(() => {
    deleteUrl.value = false;
  }, 1000);
};

const schema = z.object({
  input_text: z.string().min(50, {
    message: t("summary_form.errors.min_characters"),
  }),
  language: z.string().min(2).max(2),
});

type Schema = z.output<typeof schema>;
const { showProModal, description, title } = useProModal();
const newSummary = ref<Summary | null>(null);
const onSubmit = (_event: FormSubmitEvent<Schema>) => {
  if (!userCanProcessSummaries()) {
    title.value = t("summary_form.no_texts_left.title");
    description.value = t("summary_form.no_texts_left.description");
    showProModal.value = true;
    return;
  }

  submit();
};
const toast = useToast();
const abortController = new AbortController();
const { submit, validationErrors } = useSubmit(
  () => {
    processing.value = true;
    return sanctumClient<Summary>("/summaries", {
      method: "POST",
      body: state.value,
      signal: abortController.signal,
    });
  },

  {
    onSuccess: (response: Summary) => {
      summaryResponse.value = response;
      if (response.status === STATUS_FAILED) {
        processing.value = false;
        toast.add({
          title: t("summary_form.errors.unexpected"),
          icon: "i-ph-x-circle-fill",
          color: "red",
        });
        return;
      }

      // Add to summary list
      newSummary.value = response;

      if (response.status === STATUS_SUCCESS) {
        showSuccessToast();
        incrementSummariesProcessed();
      }
      if (isAuthenticated.value) {
        return navigateTo(localePath(`/tools/summaries/${response.id}`));
      } else if (response.status === STATUS_PENDING) {
        startWebSocket(response.id);
      } else {
        emit("summaryCreated", response);
        processing.value = false;
      }
    },
    onError: (response) => {
      processing.value = false;

      if (abortController.signal.aborted) {
        return;
      }
      if (validationErrors.value.length > 0) {
        form.value?.setErrors(validationErrors.value);
      } else if (response.status === 403) {
        setReasonForPurchase(
          SOURCE_TOOL_SUMMARIZER,
          REASON_PURCHASE_FREE_LIMIT,
        );
        title.value = t("summary_form.no_texts_left.title");
        description.value = t("summary_form.no_texts_left.description");
        showProModal.value = true;
      } else {
        toast.add({
          title: "Error",
          description: response.data?.message
            ? response.data?.message
            : t("summary_form.errors.unexpected"),
          icon: "i-ph-x-circle-fill",
          color: "red",
        });
      }
    },
  },
);

const showSuccessToast = () => {
  toast.add({
    title: t("summary_form.success.title"),
    description: t("summary_form.success.description"),
    icon: "i-ph-check-circle-fill",
    color: "green",
  });
};

onUnmounted(() => {
  abortController.abort();
  leaveEcho();
});
declare const window: any;
type StreamInfo = {
  id: string;
  index: number;
  text: string;
  status: string;
};

const leaveEcho = () => {
  if (summaryResponse.value && window.Echo) {
    window.Echo.leave(`summary.${summaryResponse.value.id}`);
    disconnectEcho();
  }
};
const startWebSocket = (id: string) => {
  startEcho();
  processing.value = true;

  window.Echo.channel(`summary.${id}`).listen(
    ".SummaryUpdated",
    (streamInfo: StreamInfo) => {
      const status = streamInfo.status;
      summaryResponse.value!.status = status;
      if (status === STATUS_SUCCESS) {
        incrementSummariesProcessed();

        summaryResponse.value!.content = streamInfo.text;
        processing.value = false;
        showSuccessToast();
        emit("summaryCreated", summaryResponse.value);
        leaveEcho();
      } else if (status === STATUS_EXPIRED || status === STATUS_FAILED) {
        processing.value = false;

        toast.add({
          title: "Error",
          description: t("summaries.edit.failed.description_without_text"),
          icon: "i-ph-warning-fill",
          color: "red",
        });
        leaveEcho();
      }
    },
  );
};

const { setReasonForPurchase } = usePurchase();

const redirectToCheckout = () => {
  setReasonForPurchase(SOURCE_TOOL_SUMMARIZER, REASON_PURCHASE_CHARACTERS);
  return isAuthenticated.value
    ? localePath("/checkout/pricing")
    : localePath("register");
};
</script>

<template>
  <div>
    <div style="box-shadow: 0px 4px 38.6px 0px rgba(128, 69, 253, 0.15)">
      <UCard
        :ui="{
          ring: 'ring-gray-300',
          body: {
            base: '',
            background: '',
            padding: 'sm:p-0 px-0 py-0 sm:px-0',
          },
          header: {
            base: '',
            background: '',
            padding: 'sm:p-0 px-0 py-0 sm:px-0',
          },
          footer: {
            base: '',
            background: '',
            padding: 'px-2 py-2 sm:px-2',
          },
        }"
      >
        <template #header>
          <div class="flex justify-between w-full">
            <YoutubeSubtitles
              v-if="limits?.free_summary_youtube_limit == 0"
              :language="state.language"
              :disabled="processing"
              :source-tool="SOURCE_TOOL_SUMMARIZER"
              class="flex-1"
              :delete-url="deleteUrl"
              @subtitles-created="onTextExtracted"
              @loading="loadingText = $event"
            />
            <UInput
              v-else
              variant="none"
              :ui="{
                base: 'border-b-1',
                rounded: 'rounded-lg',
              }"
              size="xl"
              :placeholder="$t('youtube.placeholder')"
              autocomplete="off"
              class="w-full"
              @click="
                isAuthenticated
                  ? navigateTo(localePath('/tools/summaries'))
                  : navigateTo(localePath('/register'))
              "
            >
              <template #leading>
                <UIcon
                  name="i-ph-youtube-logo-fill"
                  class="text-red-500 text-2xl"
                />
              </template>
            </UInput>
          </div>
        </template>
        <UForm
          ref="form"
          :validate-on="['input', 'change', 'submit']"
          :state="state"
          :schema="schema"
          @submit="onSubmit"
        >
          <div
            v-if="loadingText"
            class="h-72 flex items-center gap-2 px-3.5 py-2.5"
          >
            <LoadingProgress
              class="w-full"
              :message="$t('summary_form.loading.getting_text')"
              :hint="$t('summary_form.loading.getting_text.description')"
            />
          </div>

          <UFormGroup
            v-else
            name="input_text"
            :ui="{
              error: 'px-2.5',
            }"
          >
            <UTextarea
              v-model="state.input_text"
              :placeholder="t('summary_form.text.placeholder')"
              size="xl"
              variant="none"
              :ui="{
                base: 'h-72',
              }"
              :disabled="processing"
            />
          </UFormGroup>
        </UForm>
        <template #footer>
          <UAlert
            v-if="state.input_text.length > MAX_LENGTH"
            :icon="user?.is_subscribed ? undefined : 'i-ph-crown-fill'"
            color="primary"
            :actions="
              user?.is_subscribed
                ? undefined
                : [
                    {
                      label: t('summary_form.errors.max_characters.button'),
                      'trailing-icon': 'i-ph-arrow-right',
                      variant: 'soft',
                      size: 'md',
                      to: redirectToCheckout(),
                    },
                  ]
            "
            class="mb-2"
            :ui="{
              icon: {
                base: 'text-yellow-400',
              },
            }"
          >
            <template #title>
              <div class="flex flex-col">
                <span class="font-bold text-base">
                  {{
                    user?.is_subscribed
                      ? t("summary_form.errors.max_characters_pro.title")
                      : t("summary_form.errors.max_characters.title")
                  }}
                </span>
                <span class="text-white">
                  {{
                    user?.is_subscribed
                      ? t(
                          "summary_form.errors.max_characters_pro.description",
                          {
                            numberCharacters: state.input_text.length,
                            maxNumberCharacters: MAX_LENGTH,
                          },
                        )
                      : t("summary_form.errors.max_characters.description", {
                          numberCharacters: state.input_text.length,
                          maxNumberCharacters: MAX_LENGTH,
                        })
                  }}
                </span>
              </div>
            </template>
          </UAlert>

          <div v-else class="flex flex-col">
            <div class="flex justify-between sm:justify-between items-center">
              <div class="flex gap-2">
                <UFormGroup name="language">
                  <TextsSelectLanguage
                    v-model="state.language"
                    variant="none"
                    class="w-28"
                    :disabled="processing"
                  />
                </UFormGroup>

                <TextsImageToText
                  v-if="limits?.free_summary_text_image_limit == 0"
                  :tooltip-text="$t('summary_form.tooltip.image')"
                  :popover-text="$t('summary_form.popover.image')"
                  :source-tool="SOURCE_TOOL_SUMMARIZER"
                  @text-extracted="onTextExtracted"
                  @loading-image-text="loadingText = $event"
                />
                <UTooltip
                  v-else
                  :text="$t('summary_form.tooltip.image')"
                  :popper="{ placement: 'top' }"
                >
                  <UButton
                    variant="soft"
                    icon="i-ph-image"
                    size="sm"
                    @click="
                      isAuthenticated
                        ? navigateTo(localePath('/tools/summaries'))
                        : navigateTo(localePath('/register'))
                    "
                  />
                </UTooltip>

                <TextsUploadPDF
                  :tooltip-text="$t('summary_form.tooltip.pdf')"
                  @text-extracted="onTextExtracted"
                  @loading-pdf="loadingText = $event"
                />
              </div>

              <div class="flex items-center gap-4">
                <TextsTextInformation
                  :number-characters="state.input_text.length"
                  :number-words="numberWords(state.input_text)"
                />

                <TextsCopyButton :text="state.input_text" />
                <TextsClearButton
                  :disabled="processing || loadingText"
                  @clear="handleTrash"
                />
              </div>
            </div>
          </div>
        </template>
      </UCard>
    </div>
    <div
      class="flex flex-col-reverse md:flex-row gap-2 mt-4"
      :class="isAuthenticated ? 'justify-between' : 'justify-end'"
    >
      <SummariesList v-if="isAuthenticated" :new-summary="newSummary" />
      <div>
        <UButton
          v-if="state.input_text.length <= MAX_LENGTH"
          :label="t('summary_form.button')"
          trailing-icon="i-ph-arrow-right"
          :loading="processing"
          :disabled="processing || loadingText || state.input_text.length < 50"
          block
          size="md"
          @click="form.submit()"
        />
      </div>
    </div>
  </div>
</template>

<style></style>
