<script lang="ts" setup>
definePageMeta({
  title: "landing.summarizer.title",
  description: "landing.summarizer.description",
  layout: "guest-layout",
});

const { t, locale } = useI18n();
const localePath = useLocalePath();
const { isAuthenticated } = useSanctumAuth();
const faq = [
  {
    label: t("landing.summarizer.faq.1.label"),
    content: t("landing.summarizer.faq.1.content"),
  },
  {
    label: t("landing.summarizer.faq.2.label"),
    content: t("landing.summarizer.faq.2.content"),
  },
  {
    label: t("landing.summarizer.faq.3.label"),
    content: t("landing.summarizer.faq.3.content"),
  },
  {
    label: t("landing.summarizer.faq.4.label"),
    content: t("landing.summarizer.faq.4.content"),
  },
  {
    label: t("landing.summarizer.faq.5.label"),
    content: t("landing.summarizer.faq.5.content"),
  },
  {
    label: t("landing.summarizer.faq.6.label"),
    content: t("landing.summarizer.faq.6.content"),
  },
  {
    label: t("landing.summarizer.faq.7.label"),
    content: t("landing.summarizer.faq.7.content"),
  },
];
</script>

<template>
  <div>
    <ULandingHero
      :ui="{
        wrapper: 'py-4 sm:py-8 md:py-8',
        container: 'gap-4 sm:gap-y-4',
        description: 'mt-2 text-base',
      }"
      :title="$t('landing.summarizer.hero.title')"
      :description="$t('landing.summarizer.hero.description')"
    >
      <SummariesGuestSummarizer />
    </ULandingHero>

    <ULandingSection
      style="
        background: linear-gradient(
          180deg,
          #f8f4ff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      "
      :ui="{
        wrapper: 'pt-16 sm:pt-20 pb-0 sm:pb-0',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.benefits.title" scope="global">
          <span class="text-primary">
            {{ $t("landing.summarizer.benefits.title.span") }}
          </span>
        </i18n-t>
      </template>
    </ULandingSection>
    <ULandingSection
      :description="$t('landing.summarizer.benefits.1.description')"
      align="left"
      :ui="{
        title: 'text-center lg:text-left',
        description: 'text-center lg:text-left',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.benefits.1.title" scope="global">
          <span class="text-violet-500">{{
            $t("landing.summarizer.benefits.1.span")
          }}</span>
        </i18n-t>
      </template>
      <NuxtImg
        src="/images/summarizer/students-saving-time.webp"
        :alt="$t('landing.summarizer.benefits.1.alt')"
        width="703"
        height="480"
        loading="lazy"
      />
    </ULandingSection>
    <ULandingSection
      :description="$t('landing.summarizer.benefits.2.description')"
      align="right"
      :ui="{
        title: 'text-center lg:text-left',
        description: 'text-center lg:text-left',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.benefits.2.title" scope="global">
          <span class="text-blue-500">{{
            $t("landing.summarizer.benefits.2.span")
          }}</span>
        </i18n-t>
      </template>
      <NuxtImg
        :src="
          locale === 'es'
            ? '/images/summarizer/tool.webp'
            : '/images/summarizer/tool-en.webp'
        "
        :alt="$t('landing.summarizer.benefits.2.alt')"
        :width="locale === 'es' ? '689' : '779'"
        :height="locale === 'es' ? '504' : '504'"
        loading="lazy"
      />
    </ULandingSection>
    <ULandingSection
      :description="$t('landing.summarizer.benefits.3.description')"
      align="left"
      :ui="{
        title: 'text-center lg:text-left',
        description: 'text-center lg:text-left',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.benefits.3.title" scope="global">
          <span class="text-violet-500">{{
            $t("landing.summarizer.benefits.3.span")
          }}</span>
        </i18n-t>
      </template>
      <NuxtImg
        src="/images/summarizer/youtube.webp"
        :alt="$t('landing.summarizer.benefits.3.alt')"
        width="649"
        height="598"
        loading="lazy"
      />
    </ULandingSection>
    <ULandingSection
      :description="$t('landing.summarizer.benefits.4.description')"
      align="right"
      :ui="{
        title: 'text-center lg:text-left',
        description: 'text-center lg:text-left',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.benefits.4.title" scope="global">
          <span class="text-blue-500">{{
            $t("landing.summarizer.benefits.4.span")
          }}</span>
        </i18n-t>
      </template>
      <NuxtImg
        :src="
          locale === 'es'
            ? '/images/summarizer/tool-mobile.webp'
            : '/images/summarizer/tool-mobile-en.webp'
        "
        :alt="$t('landing.summarizer.benefits.4.alt')"
        width="608"
        height="527"
        loading="lazy"
      />
    </ULandingSection>
    <ULandingSection
      :description="$t('landing.summarizer.benefits.5.description')"
      align="left"
      :ui="{
        title: 'text-center lg:text-left',
        description: 'text-center lg:text-left',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.benefits.5.title" scope="global">
          <span class="text-violet-500">{{
            $t("landing.summarizer.benefits.5.span")
          }}</span>
        </i18n-t>
      </template>
      <NuxtImg
        src="/images/summarizer/easy-use.webp"
        :alt="$t('landing.summarizer.benefits.5.alt')"
        width="735"
        height="735"
        loading="lazy"
      />
    </ULandingSection>

    <ULandingSection
      :ui="{
        wrapper: 'bg-points',
      }"
    >
      <template #title>
        <i18n-t keypath="landing.summarizer.testimonials.title" scope="global">
          <span class="text-primary">{{
            $t("landing.summarizer.testimonials.count")
          }}</span>
        </i18n-t>
      </template>
      <div class="flex flex-col lg:flex-row gap-12 relative">
        <LandingTestimonial
          name="Laura"
          :description="$t('landing.summarizer.testimonials.1.description')"
          avatar="/images/text-summarizer/review-1.webp"
          :quote="$t('landing.summarizer.testimonials.1.quote')"
          :alt="$t('landing.summarizer.testimonials.1.alt')"
        />
        <LandingTestimonial
          name="John"
          :description="$t('landing.summarizer.testimonials.2.description')"
          avatar="/images/text-summarizer/review-2.webp"
          :quote="$t('landing.summarizer.testimonials.2.quote')"
          :alt="$t('landing.summarizer.testimonials.2.alt')"
        />
        <LandingTestimonial
          name="Adriana"
          :description="$t('landing.summarizer.testimonials.3.description')"
          avatar="/images/text-summarizer/review-3.webp"
          :quote="$t('landing.summarizer.testimonials.3.quote')"
          :alt="$t('landing.summarizer.testimonials.3.alt')"
        />
      </div>
    </ULandingSection>
    <ULandingSection
      id="cta-purple"
      :description="$t('landing.summarizer.cta.description')"
      :ui="{
        wrapper: 'relative overflow-x-clip py-36',
        container: 'gap-12 sm:gap-y-12',
        title: 'w-full lg:w-2/3',
        description: 'w-full lg:w-2/3',
      }"
      :card="false"
    >
      <template #title>
        {{ $t("landing.summarizer.cta.title") }}
        <span class="text-primary">Parafrasist</span>
      </template>
      <template #links>
        <div class="relative w-full lg:w-auto">
          <UButton
            :label="$t('landing.summarizer.cta.button')"
            icon="i-heroicons-rocket-launch"
            size="xl"
            block
            :to="
              isAuthenticated
                ? localePath('/tools/summaries')
                : localePath('register')
            "
          />
        </div>
      </template>
    </ULandingSection>

    <ULandingSection
      :title="$t('landing.summarizer.faq.title')"
      :description="$t('landing.summarizer.faq.description')"
    >
      <ULandingFAQ :items="faq" multiple />
    </ULandingSection>
  </div>
</template>
