<script lang="ts" setup>
const props = defineProps({
  newSummary: {
    type: Object as PropType<Summary | null>,
    required: false,
    default: null,
  },
});

const {
  data: summaries,
  error,
  status,
  refresh,
} = useAsyncData<Pagination<Summary>>(
  "summaries",
  () =>
    sanctumClient("/user/summaries", {
      onResponseError: ({ response }) => useLoginError(response),
    }),
  {
    lazy: true,
    server: false,
  },
);

watch(
  () => props.newSummary,
  (value) => {
    if (value) {
      summaries.value!.data!.unshift(value);
    }
  },
);
const sanctumClient = useSanctumClient();
const localePath = useLocalePath();

const links = computed(() => {
  return summaries.value?.data.map((summary) => ({
    label: summary.input_text.substring(0, 50) + "...",
    click: () => {
      showSlideover.value = false;
      return navigateTo(localePath(`/tools/summaries/${summary.id}`));
    },
  }));
});

const onSubmit = () => {
  if (summaries.value?.meta.next_cursor === null) {
    return;
  }

  submit();
};
const { submit, inProgress } = useSubmit(
  () =>
    sanctumClient("/user/summaries", {
      method: "GET",
      params: {
        cursor: summaries.value?.meta.next_cursor,
      },
    }),
  {
    onSuccess: (response) => {
      summaries.value!.data!.push(...response.data);
      summaries.value!.meta = response.meta;
    },
  },
);

const showSlideover = ref(false);
const onClose = () => {
  showSlideover.value = false;
};
</script>

<template>
  <div>
    <UButton
      v-if="summaries && summaries.data.length > 0"
      :label="$t('summaries_list.label')"
      variant="outline"
      icon="i-ph-list-dashes"
      block
      size="md"
      @click="showSlideover = true"
    />
    <UDashboardSlideover
      v-model="showSlideover"
      :title="$t('summaries_list.label')"
      @close="onClose"
    >
      <div>
        <FetchErrorCard
          v-if="error"
          :message="$t('summaries_list.error.fetch')"
          @refresh="refresh()"
        />
        <div
          v-else-if="status === 'pending'"
          class="grid grid-cols-1 lg:grid-cols-4 gap-8"
        >
          <USkeleton v-for="i in 14" :key="i" class="w-full h-8 rounded-lg" />
        </div>
        <div v-else-if="summaries">
          <UVerticalNavigation
            :ui="{
              base: 'justify-start',
            }"
            :links="links"
          />
          <div v-if="summaries.data.length === 0">
            <p class="text-gray-500 italic text-center">
              {{ $t("summaries_list.no_summaries") }}
            </p>
          </div>
          <div
            v-if="summaries?.meta.next_cursor === null"
            class="flex justify-center py-4"
          >
            <span class="italic">
              {{ $t("summaries_list.no_more") }}
            </span>
          </div>
          <div v-else class="flex justify-center mt-8">
            <UButton
              icon="i-ph-arrow-down"
              color="white"
              size="md"
              :label="$t('summaries_list.load_more')"
              :loading="inProgress"
              @click="onSubmit"
            />
          </div>
        </div>
      </div>
    </UDashboardSlideover>
  </div>
</template>

<style></style>
