<script lang="ts" setup>
const summary = ref<Summary | null>(null);
const selectedIndex = ref(0);

const onSummaryCreated = (text: Summary) => {
  summary.value = text;
  selectedIndex.value = 1;
};
</script>

<template>
  <div>
    <UTabs
      v-if="summary"
      v-model="selectedIndex"
      class="lg:hidden"
      :items="[
        {
          label: $t('summaries.tab.original'),
        },
        {
          label: $t('summaries.tab.summary'),
        },
      ]"
    />
    <div class="flex justify-between gap-8">
      <SummariesSummaryForm
        class="w-full lg:w-1/2"
        :class="{
          'hidden lg:block': selectedIndex,
        }"
        @summary-created="onSummaryCreated"
      />

      <SummariesGuestResult
        class="w-full lg:w-1/2"
        :class="{
          'hidden lg:block': !selectedIndex,
        }"
        :text="summary"
      />
    </div>
  </div>
</template>

<style></style>
