<script lang="ts" setup>
const props = defineProps({
  text: {
    type: Object as PropType<Summary | null>,
    required: false,
    default: null,
  },
});

const summary = ref<Summary | null>(null);

watch(
  () => props.text,
  (value) => {
    summary.value = value;
  },
);
const { processing } = useSummary();
const editor = ref();
const onEditorCreated = (e: any) => {
  editor.value = e;
};
onUnmounted(() => {
  processing.value = false;
});
</script>

<template>
  <div>
    <div
      class="rounded-lg w-full border border-gray-300"
      style="box-shadow: 0px 4px 38.6px 0px rgba(128, 69, 253, 0.15)"
    >
      <UCard
        :ui="{
          ring: 'ring-gray-300',
          body: {
            base: '',
            background: '',
            padding: 'sm:p-0 px-0 py-0 sm:px-0',
          },
          header: {
            base: '',
            background: '',
            padding: 'sm:p-0 px-0 py-0 sm:px-0',
          },
          footer: {
            base: '',
            background: '',
            padding: 'px-2 py-2 sm:px-2',
          },
        }"
      >
        <span class="px-3.5 py-2.5 block font-semibold"
          >{{ $t("text_summarizer_result.result") }}:</span
        >
        <div
          v-if="summary?.content && !processing"
          class="h-72 overflow-y-auto px-3.5 py-2.5"
        >
          <EditorBlockEditor
            v-model="summary.content"
            @editor-created="onEditorCreated"
          />
        </div>

        <div v-else-if="!summary?.content || processing" class="relative">
          <div class="h-72 overflow-y-auto px-3.5 py-2.5 flex flex-col">
            <LoadingProgress
              v-if="processing"
              :message="$t('summaries.edit.loading')"
              class="w-full mb-2"
            />
            <USkeleton v-if="processing" class="w-full h-full" />
          </div>
        </div>
        <template #footer>
          <TextsActionsText
            :text="summary?.content"
            :number-characters="editor?.storage.characterCount.characters()"
            :number-words="editor?.storage.characterCount.words()"
          />
        </template>
      </UCard>
    </div>
  </div>
</template>

<style></style>
